import React from 'react'
import googleIconWhite from '../../images/google-white.svg'
import { SignupBar } from './SignupBar'

export const GoogleSignupBar: React.FC = () => (
  <SignupBar
    title="Google"
    description="Sign up for Qualdesk using your Google account"
    iconSrc={googleIconWhite}
    url={process.env.AUTH_GOOGLE_URL}
  />
)
